import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface SocialItem {
  id: number;
  url: string;
  name: string;
  icon: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
}

interface Data {
  id: number;
  attributes: {
    createdAt: string;
    updatedAt: string;
    socialitems: SocialItem[];
  };
}

interface Pagination {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
}

interface Meta {
  pagination: Pagination;
}

interface IconResponse {
  data: Data[];
  meta: Meta;
}

@Injectable({
  providedIn: 'root'
})
export class SocialService {
  private apirooturl = 'https://strapi.nirudo.com';

  constructor(private http: HttpClient) { }

  getSocialItems(): Observable<SocialItem[]> {
    const iconurl = this.apirooturl + '/api/social-links?populate[0]=socialitems.icon';
    return this.http.get<IconResponse>(iconurl).pipe(
      map(response => response.data.flatMap(item => item.attributes.socialitems)),
      catchError(error => {
        console.error(error);
        return [];
      })
    );
  }
}
