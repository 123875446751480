<div *ngIf="error">{{error}}</div>
<div class="background">
  <picture>
    <source media="(min-height: 2160px)" srcset="{{ apirooturl }}{{background?.url}}">
    <source media="(min-height: 0px)" srcset="{{ apirooturl }}{{background?.formats?.large?.url}}">
    <img src="{{ apirooturl }}{{background?.url}}" alt="Impai">
  </picture>
  <div id="textbox1" class="textbox1">
    <nav class="menu">
      <a>—</a>
      <a onclick="menuNavigation('main')">main</a>
      <a onclick="menuNavigation('music')">music</a>
      <a onclick="menuNavigation('links')">links</a>
      <a>—</a>
    </nav>
      
    <div *ngIf="main?.videoOverride?.data; else mText" id="main" class="contentblock active">
      <video class="movie" width="90%" oncanplay="this.play()" onloadedmetadata="this.muted = true, this.loop = true">
        <source src="{{ apirooturl }}{{ main?.videoOverride?.data?.attributes?.url }}">
        Your browser does not support the video tag
      </video>
    </div>
    <ng-template #mText>
      <p>{{ main?.MainText }}</p>
    </ng-template>
      
    <div id="music" class="contentblock">
      <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/1cRcRB1GuleEp6RPrM3Nuc?utm_source=generator" width="100%" height="400px" frameborder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    </div>
      
    <div id="links" class="contentblock">
      <div *ngIf="socialItems.length > 0" class="socs">
        <a *ngFor="let si of socialItems" [href]="si.url">
          <img class="icon icon-{{ si.name | lowercase }}" src="{{ apirooturl }}{{ si.iconUrl}}" type="image/svg+xml">
        </a>
      </div>
    </div>
  </div>
</div>
