import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

interface ImageAtt {
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: boolean;
  provider: string;
  provider_metadata: boolean;
  createdAt: string;
  updatedAt: string;
  formats: {
    thumbnail: {
      name: string;
      hash: string;
      ext: string;
      mime: string;
      path: string;
      width: number;
      height: number;
      size: number;
      url: string;
    },
    large: {
      name: string;
      hash: string;
      ext: string;
      mime: string;
      path: string;
      width: number;
      height: number;
      size: number;
      url: string;
    },
    medium: {
      name: string;
      hash: string;
      ext: string;
      mime: string;
      path: string;
      width: number;
      height: number;
      size: number;
      url: string;
    },
    small: {
      name: string;
      hash: string;
      ext: string;
      mime: string;
      path: string;
      width: number;
      height: number;
      size: number;
      url: string;
    }
  }
}

interface ImageAt<T> {
  attributes: T
}

interface Entry<T> {
  id: number;
  attributes: {
    image: {
      data: {
        attributes: T;
      }
    }
  }
}

interface BGResponse {
  data: Entry<ImageAtt>;
}

@Injectable({
  providedIn: 'root'
})
export class BgService {
  private apirooturl = 'https://strapi.nirudo.com';

  constructor(private http: HttpClient) { }

  getBackground(): Observable<ImageAtt> {
    const bgurl = this.apirooturl + '/api/singlebg?populate=*';
    return this.http.get<BGResponse>(bgurl).pipe(
      map(response => response.data.attributes.image.data.attributes),
      catchError(error => {
        console.error(error);
        return [];
      })
    );
  }
}
