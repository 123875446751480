import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

interface MainData {
  MainText: string;
  videoOverride: {
    data: {
      attributes: {
        url: string;
      }
    }
  }
}

interface MResponse {
  data: {
    id: number
    attributes: MainData;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MainService {
  private apirooturl = 'https://strapi.nirudo.com';

  constructor(private http: HttpClient) { }

  getMainData(): Observable<MainData> {
    const mnurl = this.apirooturl + '/api/main?populate=*';
    return this.http.get<MResponse>(mnurl).pipe(
      map(response => response.data.attributes),
      catchError(error => {
        console.error(error);
        return [];
      })
    );
  }
}
