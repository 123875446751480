import { Component, OnInit } from '@angular/core';
import { BgService } from './bg.service';
import { MainService } from './main.service';
import { SocialItem } from './social.service';
import { SocialService } from './social.service';

interface SocialItemData {
  name: string;
  url: string;
  iconUrl: string;
}

interface ImageAtt {
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: boolean;
  provider: string;
  provider_metadata: boolean;
  createdAt: string;
  updatedAt: string;
  formats: {
    thumbnail: {
      name: string;
      hash: string;
      ext: string;
      mime: string;
      path: string;
      width: number;
      height: number;
      size: number;
      url: string;
    },
    large: {
      name: string;
      hash: string;
      ext: string;
      mime: string;
      path: string;
      width: number;
      height: number;
      size: number;
      url: string;
    },
    medium: {
      name: string;
      hash: string;
      ext: string;
      mime: string;
      path: string;
      width: number;
      height: number;
      size: number;
      url: string;
    },
    small: {
      name: string;
      hash: string;
      ext: string;
      mime: string;
      path: string;
      width: number;
      height: number;
      size: number;
      url: string;
    }
  }
}

interface MainData {
  MainText: string;
  videoOverride: {
    data: {
      attributes: {
        url: string;
      }
    }
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  error: any | undefined;
  background: ImageAtt | undefined;
  main: MainData | undefined;
  socialItems: SocialItemData[] = [];
  apirooturl: string;
  
  constructor(private bgService: BgService, private mainService: MainService, private socialService: SocialService) { 
    this.apirooturl = 'https://strapi.nirudo.com'
  }

  ngOnInit() {
    this.bgService.getBackground().subscribe(
      response => {
        this.background = response;
      },
      error => {
        this.error = error;
      }
    );

    this.mainService.getMainData().subscribe(
      response => {
        this.main = response;
      },
      error => {
        this.error = error;
      }
    );

    this.socialService.getSocialItems().subscribe(
      response => {
        this.socialItems = response.map(item => ({
          name: item.name,
          url: item.url,
          iconUrl: item.icon.data.attributes.url
        }));
      },
      error => {
        this.error = error;
      }
    );
  }
}
